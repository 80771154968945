<template>
  <div>
    <b-card id="card-twitch-filters">
      <b-row>
      <!-- Audience location -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <span class="search-Map-Pin4"></span> {{$t("search.audience_location")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_audience_location}} </p>
              </div>
            </template>
            <b-dropdown-header :key="update_location">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  :key="change_audience_location"
                  :show_remove="index > 0"
                  :index="index"
                  :location="location"
                  @changeLocationAudienceChild="changeLocationAudience"
                  @removeAudienceLocationChild="removeAudienceLocation"
                />
              </div>
              <div v-if="Object.keys(this.audience_locations[0]).length > 0">
                <hr/>
                <b-link v-show="this.audience_locations.length < 3" @click="addAudienceLocation()" class="text-secondary">{{$t('search.addLocation')}}</b-link>
                <div v-show="this.audience_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>
        
      <!-- Audience size -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <span class="search-Users"></span>{{$t("search.audience_size")}}
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_followers}} </p>
              </div>
            </template>
            <b-dropdown-header>
              <followers-filter @changeFollowers="changeFollowers" :key="update_followers_filter"/>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Viewers average -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <b-icon class="search-icon icon-size-14" icon="eye"/><span class="ml-1">{{$t("search.average_viewers")}}</span>
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{ getAvgRangeViewers() }} </p>
              </div>
            </template>
            <b-dropdown-header>
              <div v-if="average_viewers[1] === 20000" class="">≥ {{utils.getFormat(average_viewers[0])}}</div>
              <div v-else-if="average_viewers[0] === 0" class="text-right">≤ {{utils.getFormat(average_viewers[1])}}</div>
              <div v-else class="d-flex justify-content-between">
                <div>{{utils.getFormat(average_viewers[0])}}</div>
                <div>{{utils.getFormat(average_viewers[1])}}</div>
              </div>
              <slider-range class="mt-3" :init_range="average_viewers" :interval="50" :max_value="20000" :min_value="0" identifier="average_viewers" @changeSlide="changeSlideComponent" :key="average_viewers[0] + average_viewers[1]"/>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Audience gender -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_gender">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light mb-1 d-block">
                    <span class="search-intersex"></span> {{$t("search.audience_gender")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{audience_gender !== null ? audience_gender.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header :key="update_gender">
              <div v-for="(gender, index) in $t('instaGen')" :key="index" ref="gender_dropdown">
                <div 
                  :class="`button-gender 
                  ${index === 0 ? 'border-radius-top' : ''} 
                  ${index === $t('instaGen').length -1 ? 'border-radius-bottom' : ''}
                  `"
                  @click="setAudienceGender(gender, index)"
                >
                  {{gender.text}}
                </div>
              </div>
              
              <div v-if="audience_gender !== null ? audience_gender.value !== null : audience_gender !== null">
                <div class="mt-2 white-space-wrap">{{$t('search.more_than')}} {{percentage_gender}}% {{$t('search.of_audience')}}</div>
                <vue-slider
                  class="mt-1"
                  v-model="percentage_gender"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :max="100"
                  :min="55"
                  :interval="5"
                  @drag-end="changeSlide"
                  :tooltip-formatter="'{value}%'"
                />
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Submit button -->
        <b-col class="button-submit-searcher col-md-6 col-md-4 col-lg">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()" :key="`change_submit_${loading}`"/>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="m-0 p-0 d-flex justify-bewteen">
      <b-col class="col-12 col-lg-8 col-xl-9 p-0 pr-lg-1">
        <search-by
          :key="update_search_by"
          :keyword_dad="keywords"
          :where_dad="where"
          @add_keyword_filter="addKeywordFilter"
        />
      </b-col>
      <b-col class="col-12 col-lg-4 col-xl-3 p-0">
        <b-row>
          <b-col cols="9" class="pr-0">
            <div class="more-filters-button">
              <b-button 
                variant="outline"
                @click="open_sidebar = true"
                class="d-flex justify-content-center"
              > 
                <div>
                  <b-icon icon="sliders"></b-icon>
                  <span class="ml-1">{{$t('search.more_filters')}}</span>
                </div>
                <div class="d-flex justify-content-center filters_used_number d-block ml-1" v-if="checkFiltersUsed().length > 0"> 
                  <span>{{checkFiltersUsed().length}}</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="clear-filters-button">
              <b-button v-b-tooltip.hover :title="$t('search.reset_filters')" class="d-flex justify-content-center" variant="outline" @click="clearFilters()">
                <b-icon icon="trash" class="cursor-pointer"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel" class="mt-1 d-block col-xl-9 col-md-6 col-lg-6 col-12"/>
    <b-sidebar 
      v-model="open_sidebar"
      backdrop
      shadow
      right
      width="700px"
      body-class="d-flex flex-column background-white"
      no-header
      footer-class="background-white footer-sidebar-filters"
      class="sidebar-filters-nets"
    >
    <feather-icon
        class="close-component"
        icon="XIcon"
        size="16"
        @click="open_sidebar = false"
    />
      <b-row class="p-2">
        <b-col>
          <div>
            <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
          </div>

          <!-- Idioma -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_language">
              <b-form-select
                v-model="influencer_language"
                :options="$t('instaIdioma')"
              />
            </div>
          </div>

          <!-- Información de contacto -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_info_contact">
              <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
            </div>
          </div>
        </b-col>

        <b-col>
          <!-- Filtros rendimiento -->
          <div>
            <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
          </div>

          <!-- Aumento de seguidores -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('increase_followers_days')">
                  <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_increase_followers_days"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_increase_followers_days"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_increase_followers_days">
              <b-row>
                <b-col cols="12">
                  <b-form-select
                    v-model="increase_followers_days"
                    :options="$t('days')"
                />
                </b-col>
                <b-col cols="12" v-if="increase_followers_days">
                  <span class="align-middle mr-1">
                    <strong>
                      {{increase_followers_days}} {{$t("search.daysAlmost")}} {{average_increase_followers}}%
                    </strong>
                  </span>
                </b-col>
                <b-col cols="12" v-if="increase_followers_days">
                  <vue-slider
                    v-model="average_increase_followers"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Tiempo de transmisión en los últimos 7 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('hours_stream')">
                <strong><b-icon class="search-icon" icon="display"/>{{$t("search.timeStreaming")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_hours_stream"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_hours_stream"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_hours_stream">
              <b-form-select
                v-model="hours_stream"
                :options="$t('hoursStream')"
                :placeholder="$t('emptyComboAll')"
              />
            </div>
          </div>

          <!-- Días activos -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('days_active')">
                <strong><b-icon class="search-icon" icon="check-circle"/>{{$t("search.daysActive")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_days_active"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_days_active"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_days_active">
              <b-form-select
                v-model="days_active"
                :options="$t('daysActive')"
                :placeholder="$t('emptyComboAll')"
              />
            </div>
          </div>

          <!-- Juegos -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('period_games')">
                <strong><b-icon class="search-icon" icon="controller"/>{{$t("search.gamesPlayed")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_period_games"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_period_games"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_period_games">
              <b-form-select
                v-model="period_games"
                :options="$t('periodGames')"
                class="mb-2"
              />
              <vue-autosuggest
                v-if="period_games"
                :suggestions="games"
                :limit="10"
                :input-props="input_games"
                @input="inputGame"
                v-model="game"
                :get-suggestion-value="getGame"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.title }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </div>
        </b-col>
      </b-row>
      <template #footer>
        <!-- Button submit -->
        <b-col cols="12" class="button-submit-searcher col-12 mb-3 d-flex flex-wrap">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>

          <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
            {{$t('search.reset_filters')}}
          </b-button>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormSelect, BButton, BSidebar, BDropdown, BDropdownHeader, BLink, BIcon, VBTooltip
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import service from '@/services/search'
import utils from '@/libs/utils'
import axios from 'axios'
import config from '@/services/config'
import VueSlider from 'vue-slider-component'
import AudienceLocationFilter from '@/views/buscador/filters/audience_locations.vue'
import FollowersFilter from '@/views/buscador/filters/followers.vue'
import buttonSubmitFilters from './buttonSubmitFilters.vue'
import sliderRange from './sliderRange.vue'
import applyFilters from './filters/applyFilters.vue';
import SearchBy from '@/views/components/inputs/SearchBy.vue';
import OptionsFilters from '@/views/components/button-group/OptionsFilters.vue';

export default {
  name: 'FilterTwitch',
  components: {
    OptionsFilters,
    BIcon,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    VueAutosuggest,
    VueSlider,
    BDropdown,
    BDropdownHeader,
    BLink,
    AudienceLocationFilter,
    FollowersFilter,
    buttonSubmitFilters,
    sliderRange,
    applyFilters,
    SearchBy,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      update_info_contact: false,
      update_search_by: false,
      update_location: false,
      update_gender: false,
      update_followers_filter: false,
      open_sidebar: false,
      utils,
      loading: false,
      formatter: v => `${utils.numberFormat(v)}`,
      formatter_commas: v => `${utils.separatebycomma(v)}`,
      influencer_language: null,
      info_contact: null,
      total_results: 0,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      cancel_location: null,
      audience_gender: null,
      increase_followers_days: null,
      average_increase_followers: 50,
      average_viewers: [0, 20000],
      hours_stream: null,
      days_active: null,
      period_games: null,
      games: [],
      input_games: {
        class: 'form-control',
        placeholder: this.$t("search.typeGame")
      },
      game: '',
      game_selected: null,
      cancel_game: null,
      percentage_gender: 55,
      audience_locations: [{}],
      audience_locations_type: [],
      audience_locations_id: [],
      audience_locations_percentage: [],
      text_audience_location: this.$t('search.realty.any'),
      change_audience_location: false,
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      filters: [
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null, default: null}],
          value: null,
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'info_contact', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
        },
        {
          filter: 'increase_followers_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'increase_followers_days', value: null, default: null},
            {name: 'average_increase_followers', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'hours_stream',
          text: this.$t("search.timeStreaming"),
          models: [{name: 'hours_stream', value: null, default: null},],
          value: null,
        },
        {
          filter: 'days_active',
          text: this.$t("search.daysActive"),
          models: [{name: 'days_active', value: null, default: null},],
          value: null,
        },
        {
          filter: 'period_games',
          text: this.$t("search.gamesPlayed"),
          models: [
            {name: 'period_games', value: null, default: null},
            {name: 'game', value: null, default: ''},
          ],
          value: null,
        },
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
        },
        {
          filter: 'average_viewers',
          text: this.$t("search.averageViewers"),
          models: [{name: 'average_viewers', value: null, default: [0, 20000]}],
          value: null,
        },
        {
          filter: 'audience_gender',
          text: this.$t("search.audienceGender"),
          models: [
            {name: 'audience_gender', value: null, default: null},
            {name: 'percentage_gender', value: null, default: 55}
          ],
          update_component: 'update_gender',
          value: null,
        },
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'audience_locations', value: null, default: [{}]},
            {name: 'text_audience_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'name',
            },
            {
              type: 'string',
              name: ' > ',
            },
            {
              type: 'var',
              name: 'percentage'
            },
            {
              type: 'string',
              name: '%',
            },
          ]
        },
        {
          filter: 'search_by_influencer',
          text: 'anywhere',
          models: [
            {name: 'keywords', value: null, default: ''},
            {name: 'where', value: null, default: 'anywhere'}
          ],
          update_component: 'update_search_by',
          value: null,
          multiple_values: false
        },
      ],
      searched: true,
      keywords: '',
      where: 'anywhere',
    }
  },
  created () {
    this.getCounter();
    this.searched = true
  },
  computed: {
  },
  methods: {
    filterHasContacts(options) {
      this.info_contact = options.value
    },
    addKeywordFilter(new_models, keywords, where) {
      this.keywords = keywords;
      this.where = where;
      this.filters.find((item) => item.filter === 'search_by_influencer').text = this.$t(`search.${this.where}`);
      this.changeFilter('search_by_influencer', new_models, this.keywords);
      this.getCounter();
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      to_deleted.models.forEach((item) => {
        this[item.name] = item.default;
        item.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter)
      if (get_counter) this.getCounter();
      this.searched = true
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    clearFilters() {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })

      this.sendFilters();
      this.getCounter();
      this.open_sidebar = false;
      this.searched = true
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    setAudienceGender(gender, index) {
      this.audience_gender = gender;
      this.$refs.gender_dropdown.map((item, i) => {
        if (i === index) item.classList.add('background-clicked-form');
        else item.classList.remove('background-clicked-form');
      });
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    getRangeViewers() {
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) {
        return {
          'from': this.average_viewers[0],
          'to': this.average_viewers[1]
        }
      } else if (this.average_viewers[0] > 0 && this.average_viewers[1] === 20000) {
        return {
          'from': this.average_viewers[0],
          'to': null
        }
      } else if (this.average_viewers[0] === 0 && this.average_viewers[1] < 20000) {
        return {
          'from': null,
          'to': this.average_viewers[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getAvgRangeViewers() {
      let sentence = '';
      if (this.average_viewers[1] === 20000) sentence = `≥ ${utils.getFormat(this.average_viewers[0])}`
      else if (this.average_viewers[0] === 0)  sentence = `≤ ${utils.getFormat(this.average_viewers[1])}`
      else sentence = `${utils.getFormat(this.average_viewers[0])} - ${utils.getFormat(this.average_viewers[1])}`;
      return sentence;
    },
    getCounter() {
      this.searched = false
      const filters = this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })

      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    addAudienceLocation() {
      this.audience_locations.push({})
    },
    removeAudienceLocation(location, index, force_remove = false, from_filters = false) {
      const self = this
      setTimeout(function() {
        const is_show = self.$refs.filter_audience_location.$el.querySelector('ul').classList.contains('show')
        if (!from_filters && !is_show && force_remove) self.$refs.filter_audience_location.$el.querySelector('button').click()
      }, 5)

      if (force_remove) this.audience_locations.splice(index, 1)
      else this.audience_locations[index] = {}

      if (!location) return

      if (this.audience_locations.length === 0) {
        this.deleteFilter('audience_locations')
        this.text_audience_location = this.$t('search.realty.any')
      } else {
        let counter_empty = 0;
        const location_length = this.audience_locations.filter((item) => item.id).length;
        this.audience_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
            this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
          } else {
            counter_empty += 1;
          }
          if (this.audience_locations.length === counter_empty) {
            this.deleteFilter('audience_locations')
            this.text_audience_location = this.$t('search.realty.any');
          }
        });

        const new_models = [{name: 'audience_locations', value: this.audience_locations}]
        this.changeFilter('audience_locations', new_models, this.text_audience_location)
      }
      this.update_location = !this.update_location
      this.getCounter()
    },
    changeLocationAudience(data) {
      const index = data.key
      this.audience_locations[index].id = data.location.id
      this.audience_locations[index].type = data.location.type
      this.audience_locations[index].name = data.location.name
      this.audience_locations[index].percentage = data.percentage

      const location_length = this.audience_locations.filter((item) => item.id).length;

      this.audience_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
        }
      });

      const new_models = [{name: 'audience_locations', value: this.audience_locations}]
      this.changeFilter('audience_locations', new_models, this.text_audience_location)

      this.getCounter()
    },
    changeFollowers(data) {
      this.followers_from = data.from
      this.followers_to = data.to
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${utils.getFormat(this.followers_from)}-${utils.getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${utils.getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${utils.getFormat(this.followers_to)}`
      } else {
        value_model = null
        this.text_followers = this.$t('search.realty.any')
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)
      this.getCounter()
    },
    formatAudienceLocations() {
      this.audience_locations_id = []
      this.audience_locations_type = []
      this.audience_locations_percentage = []
      if (this.audience_locations.length > 0) {
        this.audience_locations.forEach(location => {
          if (location.id) {
            this.audience_locations_id.push(location.id)
            this.audience_locations_type.push(location.type)
            this.audience_locations_percentage.push(location.percentage)
          }
        })
      }
    },
    getFilters() {
      const finalFilters = {}
      const viewers = this.getRangeViewers();
      this.formatAudienceLocations()

      const data = {
        st: 'tw',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_language: this.influencer_language,
        has_contacts: this.info_contact,
        audience_location_id: this.audience_locations_id.length > 0 ? this.audience_locations_id : null,
        audience_location_type: this.audience_locations_type.length > 0 ? this.audience_locations_type : null,
        audience_location_from: this.audience_locations_percentage.length > 0 ? this.audience_locations_percentage : null,
        audience_gender_id: this.audience_gender ? this.audience_gender.value : null,
        audience_gender_from: this.audience_gender ? this.percentage_gender : null,
        followers_growth_period: this.increase_followers_days ? this.increase_followers_days : null,
        followers_growth_to: this.increase_followers_days ? this.average_increase_followers : null,
        live_viewers_avg_from: viewers.from,
        live_viewers_avg_to: viewers.to,
        hours_streamed: this.hours_stream,
        active_days_per_week: this.days_active,
        tw_games_played_period: this.game_selected ? this.period_games : null,
        tw_games_played_game: this.game_selected ? this.game_selected.id : null
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }

      return finalFilters
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    inputGame(text) {
      this.games = [];
      if (text !== '') {
        const CancelToken = axios.CancelToken;

        if (this.cancel_location) this.cancel_location()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/juegos`,
          data:{
            game: text
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel_location = c;
          })
        })
          .then(result => {
            const filteredData = []
            result.data.games.forEach(game => {
              filteredData.push(game.basic)
            })
            self.games = [{data: filteredData}]
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            }
            return []
          });
      }
    },
    getGame(suggestion) {
      this.game = suggestion.item.title
      this.game_selected = suggestion.item
      this.getCounter()
    },
    sendFilters() {
      this.searched = true
      const finalFilters = this.getFilters()
      this.$emit('llamarTwitch', finalFilters)
      this.$emit('clean_selected');
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()
    },
    changeSlide() {
      this.getCounter()
    },
    getClasses(value) {
      if (value === 0 || value === 20000) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.average_viewers[0] === 0 && this.average_viewers[1] !== 20000) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.average_viewers[1] === 20000 ? 0 : style.width;
      const width = this.average_viewers[0] === 0 ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    },
    getLabelViewers() {
      if (this.average_viewers[0] === 0 && this.average_viewers[1] === 20000) return this.$t("search.anyQuantity")
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) return `${this.$t("search.between")} ${utils.numberFormat(this.average_viewers[0])} ${this.$t("search.and")} ${utils.numberFormat(this.average_viewers[1])}`
      if (this.average_viewers[0] > 0) return `${this.$t("search.lessThan")} ${utils.numberFormat(this.average_viewers[0])}`
      if (this.average_viewers[1] < 20000) return `${this.$t("search.greaterThan")} ${utils.numberFormat(this.average_viewers[1])}`

      return this.$t("search.anyQuantity")
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)

      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }

      this.input_games = {
        class: 'form-control',
        placeholder: this.$t("search.typeGame")
      }
    },
    average_viewers(val) {
      const value_filter = this.getAvgRangeViewers();
      const new_models = [{name: 'average_viewers', value: value_filter !== '≥ 0' ? val : null}]
      if (value_filter !== '≥ 0') {
        this.changeFilter('average_viewers', new_models, value_filter)
      } else {
        this.changeFilter('average_viewers', new_models, value_filter)
      }
    },
    influencer_language(val) {
      this.update_filters_view = !this.update_filters_view;

      const new_models = [{name: 'influencer_language', value: this.$t('instaIdioma').find((item) => item.value === val).value}]
      this.changeFilter('influencer_language', new_models, this.$t('instaIdioma').find((item) => item.value === val).text)
      this.getCounter()
    },
    info_contact(val) {
      this.update_filters_view = !this.update_filters_view;
      const new_models = [{name: 'info_contact', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      this.getCounter()
    },
    audience_gender(val) {
      const new_models = [{name: 'audience_gender', value: val ? val.value : null}]
      if (val && val.value === null) {
        new_models.push({name: 'percentage_gender', value: 55})
        this.percentage_gender = 55;
      }
      this.changeFilter('audience_gender', new_models, val ? `${val.text}, ≥${this.percentage_gender}%` : null)
      this.getCounter()
    },
    percentage_gender(val) {
      const new_models = [{name: 'percentage_gender', value: val}]
      this.changeFilter('audience_gender', new_models, `${this.audience_gender.text}, ≥${val}%`)
    },
    increase_followers_days(val) {
      const new_models = [
        {name: 'increase_followers_days', value: this.$t('days').find((item) => item.value === val).value},
        {name: 'average_increase_followers', value: this.average_increase_followers}
      ]
      this.changeFilter('increase_followers_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ≥${this.average_increase_followers}`)

      if (this.increase_followers_days === null) this.average_increase_followers = 50
      this.getCounter()
    },
    average_increase_followers(val) {
      this.changeFilter('increase_followers_days', [], `${this.$t('days').find((item) => item.value === this.increase_followers_days).text}, ≥${val}`)
    },
    set_viewers() {
      if (this.set_viewers === 'hide') this.average_viewers = [50, 20000]
      this.getCounter()
    },
    hours_stream(val) {
      const new_models = [{name: 'hours_stream', value: null},]
      this.changeFilter('hours_stream', new_models, this.$t('hoursStream').find((item) => item.value === val).text)

      this.getCounter()
    },
    days_active(val) {
      const new_models = [{name: 'days_active', value: this.$t('daysActive').find((item) => item.value === val).value},]
      this.changeFilter('days_active', new_models, this.$t('daysActive').find((item) => item.value === val).text)

      this.getCounter()
    },
    period_games() {      
      if (this.period_games === null) {
        this.game_selected = null;
        this.game = '';
      }

      if (this.game_selected) this.getCounter()
    },
    game(val) {
      const new_models = [
        {name: 'period_games', value: this.$t('periodGames').find((item) => item.value === this.period_games).value},
        {name: 'game', value: val}
      ]
      this.changeFilter('period_games', new_models, `${this.$t('periodGames').find((item) => item.value === this.period_games).text}, ${val}`)
    }
  }
}
</script>

<style type="text/css">
  .value-active {
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: none;

    width: 100%;
    height: 100%;
    background-color: white;
    transition: all .3s;
    border: 2px solid #7367f0;
  }
  .value-inactive {
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: none;
    width: 100%;
    height: 100%;
    background-color: gray;
    transition: all .3s;
    border: 2px solid white;
  }
   .filter-performance {
     border-top: #ebebeb solid 1px;
   }
</style>
<style>
#card-twitch-filters > .card-body {
  padding: 0.7em 1.7em !important;
}
#card-twitch-filters {
  margin-bottom: 0;
  box-shadow: 0 15px 18px rgb(34 41 47 / 3%) !important;
}
</style>