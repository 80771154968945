<template>
  <div>
    <vue-slider
      class="mt-1"
      :tooltip="'always'"
      v-model="range"
      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :max="max_value"
      :min="min_value"
      :interval="interval"
      @drag-end="changeSlide"
      :tooltip-formatter="formatter_commas"
    >
      <template v-slot:dot="{ value }">
        <div :class="getClasses(value)"></div>
      </template>

      <template v-slot:process="{ style, index }">
        <div class="vue-slider-process custom-class" :style="[style, getProgressStyle(style, index)]">
        </div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import utils from '@/libs/utils'

export default {
  name: 'SliderRange',
  props: {
    min_value: {
      type: Number,
      required: true
    },
    max_value: {
      type: Number,
      required: true
    },
    interval: {
      type: Number,
      required: true
    },
    init_range: {
      type: Array,
      required: true
    },
    identifier: {
      type: String,
      default: ''
    }
  },
  components: {
    VueSlider
  },
  data () {
    return {
      utils,
      formatter_commas: v => `${utils.separatebycomma(v)}`,
      range: this.init_range,
    }
  },
  created () {
  },
  methods: {
    changeSlide() {
      this.$emit('changeSlide', this.identifier, this.range)
    },
    getClasses(value) {
      if (value === this.min_value || value === this.max_value) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.range[0] !== this.min_value && this.range[1] !== this.max_value) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.range[0] === this.min_value && this.range[1] !== this.max_value) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.range[1] === this.max_value ? 0 : style.width;
      const width = this.range[0] === this.min_value ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    },
  },
}
</script>
