<template>
  <div>
    <h5 class="text-bold">{{$t('search.followers')}}</h5>

    <vue-slider
      class="mt-3"
      v-model="followers"
      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :max="1000100"
      :min="0"
      :interval="100"
      @drag-end="changeSlide"
      :tooltip-formatter="formatter"
      :tooltip="'always'"
    >
      <template v-slot:dot="{ value }">
        <div :class="getClasses(value)"></div>
      </template>

      <template v-slot:process="{ style, index }">
        <div class="vue-slider-process custom-class" :style="[style, getProgressStyle(style, index)]">
        </div>
      </template>
    </vue-slider>

    <div class="d-flex justify-content-space-evenly">
      <div class="mr-1">
        <p class="text-center m-0">{{$t('search.from')}}</p>
        <b-form-input @input="changeInput()" @change="endChangeInput()" v-model="from" type="number"></b-form-input>
      </div>
      <div class="ml-1">
        <p class="text-center m-0">{{$t('search.to')}}</p>
        <b-form-input @input="changeInput()" @change="endChangeInput()" v-model="to" type="number"></b-form-input>
      </div>
    </div>

    <div class="mt-2">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-for="(size, index) in sizes"
          :key="index"
          v-model="followers_selected"
          :aria-describedby="ariaDescribedby"
          :value="size.value"
          class="mt-1"
        >{{$t(`search.${size.text}`)}}</b-form-radio>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormRadio
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import utils from '@/libs/utils'

export default {
  name: 'FollowersFilter',
  components: {
    BFormInput,
    BFormGroup,
    BFormRadio,
    VueSlider
  },
  props: {
  },
  data () {
    return {
      utils,
      formatter: v => `${utils.numberFormat(v)}`,
      followers: [1000, 10000],
      from: 1000,
      to: 10000,
      followers_selected: null,
      sizes: [
        {"value": [1000, 10000], "text": "nano"},
        {"value": [10000, 50000], "text": "micro"},
        {"value": [50000, 500000], "text": "levelMedium"},
        {"value": [500000, 1000000], "text": "macro"},
        {"value": [1000000, null], "text": "mega"}
      ]
    }
  },
  created () {
  },
  methods: {
    emitChangeFollowers() {
      if (this.from === '') {
        this.from = null
      }

      if (this.to === '') {
        this.to = null
      }
      this.$emit('changeFollowers', {from: this.from, to: this.to})
    },
    formatRangeFollowers() {
      if (this.followers[0] !== 0 && this.followers[1] !== 1000100) {
        this.from = this.followers[0]
        this.to = this.followers[1]
      } else if (this.followers[0] > 0 && this.followers[1] === 1000100) {
        this.from = this.followers[0]
        this.to = null
      } else if (this.followers[0] === 0 && this.followers[1] < 1000100) {
        this.from = null
        this.to = this.followers[1]
      } else {
        this.from = null
        this.to = null
      }
    },
    changeSlide() {
      this.formatRangeFollowers()
      this.followers_selected = null
      this.emitChangeFollowers()
    },
    changeInput() {
      if (this.from >= this.to) {
        this.followers = [this.to, this.from]
      } else if (this.to <= this.from) {
        this.followers = [this.to, this.from]
      } else {
        this.followers = [this.from, this.to]
      }
    },
    endChangeInput() {
      const old_from = this.from
      const old_to = this.to

      if (this.to && this.from && this.from > this.to) {
        this.from = old_to
        this.to = old_from
      } else if (this.from && this.to && this.to < this.from) {
        this.from = old_to
        this.to = old_from
      }

      this.followers_selected = null

      this.emitChangeFollowers()
    },
    getClasses(value) {
      if (value === 0 || value === 1000100) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.followers[0] !== 0 && this.followers[1] !== 1000100) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.followers[0] === 0 && this.followers[1] !== 1000100) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.followers[1] === 1000100 ? 0 : style.width;
      const width = this.followers[0] === 0 ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    }
  },
  watch: {
    followers_selected() {
      if (this.followers_selected) {
        this.followers = this.followers_selected
        this.from = this.followers_selected[0]
        this.to = this.followers_selected[1]
        this.emitChangeFollowers()
      }
    }
  }
}
</script>

<style>
  .text-bold {
    font-weight: bold;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
</style>