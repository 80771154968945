<template>
  <b-sidebar right v-model="show" class="sidebar-search" shadow backdrop @change="hasClosedSidebar" ref="sidebar_search">
    <template #header>
      <div class="w-100 d-flex justify-content-between align-items-center p-1">
        <b-link class="d-block" :href="getProfileUrl(data.actions)" target="_blanck">
          <strong class="text-dark">{{data.actions.fullname}}</strong>
          <span class="ml-1 text-muted">@{{data.username.name.username}}
            <image-verified v-if="data.username.name.is_verified"/>
          </span>
        </b-link>
        <feather-icon 
          icon="XIcon" 
          size="18" 
          class="close-sidebar-icon"
          @click="show = false"
        >
        </feather-icon>
      </div>
    </template>
    <b-row class="p-1 border-bottom-sidebar">
      <b-col class="col-12 d-flex flex-wrap">
        <div class="row w-100">
          <div class="col-6">
            <b-avatar
              :src="network === 'ig' ? data.actions.ava : data.username.avatar"
              square
              class="p-0 mb-2 avatar-sidebar-search"
            ></b-avatar>
          </div>
          
          <div class="col-6 d-flex flex-column flex-wrap font-size-13">
            <div v-if="data.followers">
              <span class="follow-er-view-card">{{$t("campaigns.followers")}}</span>
              <span class="d-block">{{ utils.getFormat(data.followers) }}</span>
            </div>
            <div class="mt-1" v-if="data.tp">
              <span class="follow-er-view-card">ER</span>
              <span class="d-block">{{data.tp}} %</span>
            </div>
            <div class="mt-1" v-if="data.audience">
              <span class="follow-er-view-card">{{$t("search.audience")}}</span>
              <span class="d-block">{{utils.getFormat(data.audience)}}</span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="col-12">
        <span class="mb-1 p-0 col-12 d-block">{{$t('search.add_to')}}:</span>
        <b-dropdown class="col-6 dropdown-color" no-caret dropright >
          <template #button-content> 
            <div class="outline-blue-hover">
              {{$t('search.campaign')}}
            </div>            
          </template>
          <b-dropdown-header>
            <h6>{{$t('search.add_to_campaign')}}</h6>
            <hr class="m-0 mb-1">
            <b-col 
              v-for="(campaign, index) in campaigns" 
              :key="index" 
              @click="campaign.with_influencer ? '' : addToCampaign(data, campaign.uuid, index)"
              :class="`col-12 dropitem-add-campaign ${campaign.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
            >
              <div class="d-flex justify-content-between container-drop-items">
                <span class="d-block"> {{campaign.object_name.substr(0, 25)}}</span>
                <feather-icon 
                  :icon="campaign.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                  :class="campaign.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
                </feather-icon>
                <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-c-${index}`"></b-spinner>
              </div>
            </b-col>
            <hr>
            <b-link target="_blank" href="/campaigns" class="d-flex align-items-center"><feather-icon icon="ExternalLinkIcon" class="margin-right-05"></feather-icon> {{$t('search.go_to_campaign')}}</b-link>
          </b-dropdown-header>
        </b-dropdown>

        <b-dropdown class="col-6 dropdown-color" no-caret dropright>
          <template #button-content> 
            <div class="outline-blue-hover">
              {{$t('search.list')}}
            </div>
          </template>
          <b-dropdown-header>
            <h6>{{$t('search.add_to_list')}}</h6>
            <hr class="m-0 mb-1">
            <b-col 
              v-for="(list, index) in lists" 
              :key="index" 
              :class="`col-12 dropitem-add-campaign ${list.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
              @click="list.with_influencer ? '' : addToList(list.uuid, index)"
            >
              <div class="d-flex justify-content-between container-drop-items">
                <span class="d-block"> {{list.object_name.substr(0, 25)}}</span>
                <feather-icon 
                  :icon="list.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                  :class="list.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
                </feather-icon>
                <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-${index}`"></b-spinner>
              </div>
            </b-col>
            <hr>
            <b-link target="_blank" href="/lists" class="d-flex align-items-center"><feather-icon icon="ExternalLinkIcon" class="margin-right-05"></feather-icon> {{$t('search.go_to_lists')}}</b-link>
          </b-dropdown-header>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row class="col-12 d-flex justify-content-center container-loader-searcher" v-if="!contents_loaded">
      <b-spinner  class="spinner-searcher-sidebar"></b-spinner>
    </b-row>
    <b-row v-if="contents_filtered.length > 0">
      <b-col class="d-flex justify-content-center">
        <b-dropdown
          no-caret
          right
          variant="flat-secondary"
          class="chart-dropdown d-block"
          toggle-class="p-50 mt-1"
          size="sm"
        >
          <template #button-content>
            <div @click="chev_icon = !chev_icon">
              {{$t(`media_ig.${sort_selected.label}`)}}
              <feather-icon :icon="chev_icon ? 'ChevronDownIcon': 'ChevronUpIcon'" ></feather-icon> 
            </div>
          </template>
          <b-dropdown-item
            v-for="(sort, index) in sorts"
            :key="index" 
            @click="changeSort(sort)"
          >
            <span >{{ $t(`media_ig.${sort.label}`) }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col class="d-flex justify-content-center flex-wrap" :key="update_image">
        <div v-for="(content, index) in contents_filtered" :key="index" class="my-2 container-thubnail">
          <b-img :src="content.basic.image" square class="thubnail-contents-sidebar" @error="errorLoad(content)" ></b-img>
          <div class="container-text-sidebar">
            <div class="mb-1 d-flex justify-content-between">
              <div>{{getDateUnixToTime((network === 'ig') ? content.basic.time_posted : content.basic.exact_create_time)}}</div>
              
              <b-link :href="formatLink(content)" target="_blank" class="text-white">
                <feather-icon icon="ExternalLinkIcon" size="15"></feather-icon>
              </b-link>
            </div>
            {{(network === 'ig') ? content.basic.caption : content.basic.text}}
            
          </div>
          <div class="container-reations-sidebar"> 
            <div class="d-flex align-items-center">
              <feather-icon icon="HeartIcon"></feather-icon>
              <span>{{utils.getFormat(network === 'ig' ? (content.metrics.likes_count ? content.metrics.likes_count : 0) : content.metrics.likes_count.value)}}</span>
            </div>
            <div class="d-flex align-items-center">
              <feather-icon icon="MessageCircleIcon"></feather-icon>
              <span>{{utils.getFormat(network === 'ig' ? content.metrics.comments_count : content.metrics.comments_count.value)}}</span>
            </div>
            <div v-if="content.metrics.views_count || content.metrics.video_views_count" class="d-flex align-items-center">
              <feather-icon icon="EyeIcon"></feather-icon>
              <span>{{utils.getFormat(network === 'ig' ? content.metrics.video_views_count : content.metrics.views_count.value)}}</span>
            </div>
            <div v-if="content.metrics.er">
              <span class="padding-right-01">ER</span>
              <span>{{network === 'ig' ? content.metrics.er : content.metrics.er.value.toFixed(2)}} %</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="network === 'tr'" class="mt-2">
      <b-col class="col-10 mx-auto">
        <timeline :id="data.username.name.username" sourceType="profile" :options="{ tweetLimit: '5' }" widget-class="card"/>
      </b-col>
    </b-row>
    <b-row v-if="network === 'tw'" class="p-1">
      <b-col>
        <div>
          <iframe 
            width="100%" height="100%" 
            :src="`https://player.twitch.tv/?autoplay=false&channel=${data.username.name.username}&parent=${host}`" 
          >
          </iframe>
        </div>
      </b-col>
    </b-row>
    <b-row class="p-1" v-if="contents_filtered.length === 0 && contents_loaded && data.category">
      <b-col>
        <h4 class="margin-category-badge">{{$t('search.categories')}}</h4>
        <div class="d-flex flex-wrap">
          <p v-for="(category, index) in data.category" :key="index" class="margin-category-badge">
            <b-badge pill variant="light-secondary-v2" class="light-secondary-v2" >{{(utils.checkNumber(category)) ? getNameCategory(category) : category}}</b-badge>
          </p>
        </div>
      </b-col>
    </b-row>
  </b-sidebar>
</template>

<script>
import { 
  BSidebar, 
  BRow, 
  BCol, 
  BAvatar, 
  BLink,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BBadge,
  BDropdownHeader,
  BImg  
} from "bootstrap-vue";
import utils from "@/libs/utils";
import profile_services from '@/services/profile';
import search_services from '@/services/search';
import lists_services from '@/services/lists'
import {Timeline} from 'vue-tweet-embed';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  name: "SidebarSearch",
  components: {
    ImageVerified,
    Timeline,
    BSidebar,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BBadge,
    BDropdownHeader,
    BImg
  },
  props: {
    open_sidebar: {
      type: Boolean
    },
    data: {
      type: Array
    },
    network: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      utils,
      show: this.open_sidebar,
      contents: [],
      sorts: [
        {
          label: 'most_recent',
          value: 'most_recent'
        },
        {
          label: 'most_engaged',
          value: 'most_engaged'
        },
        {
          label: 'most_liked',
          value: 'most_liked'
        },
        {
          label: 'most_commented',
          value: 'most_commented'
        },
        {
          label: 'most_viewed',
          value: 'most_viewed'
        },
      ],
      chev_icon: true,
      sort_selected: {
        label: 'most_recent',
        value: 'most_recent'
      },
      contents_filtered: [],
      local: window.location.origin,
      contents_loaded: false,
      lists: [],
      campaigns: [],
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      update_image: false,
    }
  },
  created() {
    if ((this.network === 'ig' || this.network === 'tt') && this.data !== null) this.getContents();
    else {
      setTimeout(() => {
        this.contents_loaded = true
      }, 1000)
    }
    this.getInfluenerIn();
  },
  computed: {
    host() {
      return window.location.host;
    }
  },
  methods: {
    getMedia(content) {
      if (this.network === 'ig') {
        return `https://klear.com/api/2.1/HostedContent/instagram_post?url=https://www.instagram.com/p/${content.basic.code}/`
      } else {
        return content.basic.thumbnail
      }
    },
    errorLoad(content) {
      content.basic.image = this.backgroundDefault;
      this.update_image = !this.update_image;
      return this.backgroundDefault;
    },
    addToCampaign(data, campaign_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
      this.$emit('add_to_campaign', data, campaign_uuid);
      setTimeout(() => {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
        this.campaigns.find((item) => item.uuid === campaign_uuid).with_influencer = true;
      }, 500);
    },
    addToList(list_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.remove('d-none');
      lists_services.bulkAddInfluencer(list_uuid, {influencers: [this.getFormatAddInfluencer()]}).then(() => {
        setTimeout(() => {
          this.lists.find((item) => item.uuid === list_uuid).with_influencer = true;
          this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.add('d-none');
        }, 500)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('lists.addinfluencer'),
            icon: "CoffeeIcon",
            variant: "success",
            text: this.$t('lists.addlist')
          }
        });
      })
    },
    getFormatAddInfluencer() {
      return {
        influencer: this.data.username.name.username,
        network: utils.getLocalNetworkName(this.network),
        followers: this.data.followers,
        image: this.network === 'ig' ? this.data.actions.ava : this.data.username.avatar
      }
    },
    getNameCategory(cat) {
      let entry = false
      switch (this.network) {
      case 'ig':
        entry = this.$t('instaCategories').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      case 'yt':
        entry = this.$t('youtubeCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;

      case 'tt':
        entry = this.$t('tiktokCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      }

      return ''
    },
    hasClosedSidebar() {
      this.$refs.sidebar_search.$el.classList.remove('open-sidebar-search')
    },
    changeSort(sort) {
      this.sort_selected = sort;
      this.contents_loaded = false;
      this.sortContents(sort.value);
    },
    sortContents(sort) {
      if (sort === 'most_recent') { 
        this.contents.sort((a, b) => {
          return this.network === 'ig' ? b.basic.time_posted - a.basic.time_posted : b.basic.exact_create_time - a.basic.exact_create_time;
        })
      } else if (sort === 'most_engaged') {
        this.contents.sort((a, b) => {
          return this.network === 'ig' ? b.metrics.er - a.metrics.er : b.metrics.er.value - a.metrics.er.value;
        })
      } else if (sort === 'most_liked') {
        this.contents.sort((a, b) => {
          return this.network === 'ig' ? b.metrics.likes_count - a.metrics.likes_count : b.metrics.likes_count.value - a.metrics.likes_count.value;
        })
      } else if (sort === 'most_commented') {
        this.contents.sort((a, b) => {
          return this.network === 'ig' ? b.metrics.comments_count - a.metrics.comments_count : b.metrics.comments_count.value - a.metrics.comments_count.value;
        })
      } else if (sort === 'most_viewed') {
        this.contents.sort((a, b) => {
          return this.network === 'ig' ? b.metrics.video_views_count - a.metrics.video_views_count : b.metrics.views_count.value - a.metrics.views_count.value;
        })
      } else {
        this.contents.sort((a, b) => {
          return b.basic.time_posted - a.basic.time_posted
        })
      }
      this.contents_filtered = this.contents.slice(0, 5);
      this.contents_filtered.map((item) => {
        item.basic['image'] = this.getMedia(item);
      })
      setTimeout(() => {
        this.contents_loaded = true;
      }, 250)
    },
    getContents() {
      profile_services[this.getWebService()[0]](this.getWebService()[1]).then((response) => {
        for (const property in response.media) {
          this.contents.push(response.media[property]);
        }
        this.sortContents('most_recent');
      });
    },
    getInfluenerIn() {
      const local_network = utils.getLocalNetworkName(this.network)
      search_services.getInfluencerIn(this.network !== 'yt' ? this.data.username.name.username : this.data.actions.channel_id, local_network , 'lists').then((response) => {
        this.lists = response.results

      });
      search_services.getInfluencerIn(this.network !== 'yt' ? this.data.username.name.username : this.data.actions.channel_id, local_network, 'campaign').then((response) => {
        this.campaigns = response.results
      });
    },
    getWebService() {
      if (this.network === 'ig') return ['getMediaInstagram', this.data.username.name.username];
      else if (this.network === 'tt') return ['getMetricsVideosTiktok', this.data.actions.social_id];
    },
    formatLink(media) {
      if (this.network === 'ig') return `https://www.instagram.com/p/${media.basic.code}/`;
      else return `https://www.tiktok.com/@${this.data.username.name.username}/video/${media.basic.id}`;
    },
    getDateUnixToTime(unix_timestamp) {
      return utils.getDatev2(new Date(unix_timestamp * 1000));
    },
    getProfileUrl(actions) {
      const network = utils.getLocalNetworkName(this.network);
      const username = this.network === 'yt' ? actions.channel_id : actions.username;
      return `${this.local}/perfil/${network}/${username}`;
    },
  }
};
</script>
<style>
.sidebar-search > .b-sidebar {
  width: 350px !important;
}
.avatar-sidebar-search {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 1em !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.sidebar-search .bg-light{
  background-color: white !important;
}
.sidebar-search .b-sidebar-header {
  padding: 1em 0;
  font-size: 13px !important;
  border-bottom: 1px solid #e2e8f0;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.container-thubnail {
  position: relative;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 1em;
}
.thubnail-contents-sidebar {
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 1em !important;
  display: block;
}
.container-thubnail:hover {
  cursor: pointer;
}
.container-thubnail:hover .container-text-sidebar {
  animation: animation-text 400ms;
  display: block;
}
.border-bottom-sidebar {
  border-bottom: 1px solid #e2e8f0;
}
.container-reations-sidebar {
  background-color: white;
  height: 50px;
  width: 280px;
  position: absolute;
  bottom: 0em;
  border-radius: 0.6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 0 0.5em;
}
.container-text-sidebar {
  background: rgb(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  top: 0;
  height: 240px;
  padding: 1em;
  width: 280px;
  border-radius: 1em;
  display: none;
  overflow: hidden;
  font-size: 13px;
}
.padding-right-01 {
  padding-right: 0.2em;
}
.spinner-searcher-sidebar {
  width: 4em;
  height: 4em;
  color: gray;
  margin: 15em 0 0 0;
}
.margin-category-badge {
  margin: 0.3em 0.3em 0.3em 0;
}
.container-drop-items {
  width: 250px;
}
.container-drop-items > a:hover {
  background-color: #ccc !important;
}
.secondary-color-icon {
  color: #718096;
}
.success-color-icon {
  color: green;
}
.outline-blue-hover .btn-group {
  border: none !important;
}
.outline-blue-hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #0096fe;
  color: #0096fe;
  padding: 11px 21px;
  border-radius: 0.5em;
}
.outline-blue-hover:hover {
  background-color: #0096fe;
  color: white;
  transition: all 300ms;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-color > button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-color > .dropdown-menu {
  top: 50px !important;
}

@keyframes animation-text {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
